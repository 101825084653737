import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled } from '@activebrands/core-web/libs/styletron';
import CloseIcon from 'components/icons/CloseIcon';
import Heading from 'components/text/Heading';

const Wrapper = styled('div', {
    position: 'relative',
    zIndex: -1,
    width: '100%',
    height: 'auto',
    backgroundColor: 'var(--color-bg-secondary)',
});

const OverlayContent = styled('div', {
    borderRadius: '8px',

    padding: '0 12px 24px 12px',

    [media.min['mobile.lg']]: {
        padding: '0 24px 24px 24px',
    },
});

const OverlayHeader = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: '12px',

    [media.min['mobile.lg']]: {
        padding: '12px 24px',
    },
});

const CloseButton = styled('button', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

type ErrorOverlayProps = {
    $style?: any;
    label: string;
    message: any;
};

const ErrorOverlay = ({ $style = {}, label, message }: ErrorOverlayProps) => (
    <Wrapper $style={$style}>
        <OverlayHeader>
            <Heading
                as="span"
                $style={{ color: 'var(--color-text-error)' }}
                fontKeys={['Primary/20_94_-1_700', null, 'Primary/24_94_-1_700'] as any}
            >
                {label}
            </Heading>
            <CloseButton type="button" onClick={() => overlay.close('error-overlay')}>
                <CloseIcon $style={{}} width="16px" />
            </CloseButton>
        </OverlayHeader>
        <OverlayContent>
            <RichText data={message} />
        </OverlayContent>
    </Wrapper>
);

export default ErrorOverlay;
